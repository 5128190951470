import {Action} from '@ngrx/store';

export const SET_AUTH_TOKEN = '[USER] Set auth token';

export class SetAuthToken implements Action {
    readonly type = SET_AUTH_TOKEN;
    constructor(public payload: any) {}
}

export type Actions = SetAuthToken;
