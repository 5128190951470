import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { Store } from '@ngrx/store';
import * as chicky from 'js-cookie';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  authToken: Observable<any>;
  constructor(
    private router: Router,
    private api: ApiService,
    private store: Store<any>,
    private zone: NgZone
  ) {
    this.store.select('authToken').subscribe(state => this.authToken = state);
   }

  ngOnInit() {
  }
  isAuth() {
  // console.log(this.authToken);
  return this.authToken === undefined ? null : this.authToken;
  }
  logout() {
    // alert('entonceeeeeeeeees !!!!!!!!!!!!!11');
    chicky.remove('authToken');
    this.zone.run(() => this.router.navigate(['/']));
   }
}
