import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as galleta from 'js-cookie';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as TokenActions from '../../app/store/actions/token.action';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  authToken: Observable<any>;
  serverUrlLocal = environment.serverUrlLocal;
  serverUrlProd = environment.serverUrlProd;
  api: HttpClient;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private store: Store<any>
  ) {
    this.store.select('authToken').subscribe(state => (this.authToken = state));
  }

  // funciones de servicios de api
  // FOR GET FUNCTIONS
  public GET(path: string) {
    return this.httpClient.get(`${this.serverUrlProd}/${path}`, {
      headers: this.getHeaders()
    });
  }

  public POST(path: string, body: any) {
    return this.httpClient.post(`${this.serverUrlProd}/${path}`, body, {
      headers: this.getHeaders()
    });
  }
  // para patch

  // para put

  // para delete

  public getHeaders() {
    let header = new HttpHeaders();
    if (this.authToken) {
      header = header.set('Authorization', `Bearer ${this.authToken}`);
    }
    // console.log(this.authToken + '\n' + header);
    return header;
  }

  // login

  logIn(nickname: string, password: string) {
    // console.log(this.serverUrlProd);
    const url = `${this.serverUrlProd}authentication/login`;
    this.httpClient.post(url, { nickname, password }).subscribe(
      data => {
        // console.log(data);
        this.store.dispatch(new TokenActions.SetAuthToken(data['auth']));
        this.redirect();
        this.storeToken(data['auth']);
      },
      error => {
        console.log('Error desde Angular con respecto al api', error);
      }
    );
  }

  redirect() {
    this.router.navigate(['/']);
  }
  storeToken(token) {
    galleta.set('authToken', token);
    // localStorage.setItem('authToken', obj['auth']) ;
  }
}
