import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as chicky from 'js-cookie';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router, private zone: NgZone) { }

  ngOnInit() {
  }
  logout() {
    // alert('entonceeeeeeeeees !!!!!!!!!!!!!11');
    chicky.remove('authToken');
    window.location.reload();
   }
}
