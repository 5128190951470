import * as TokenActions from '../actions/token.action';
import * as Cookies from 'js-cookie';

const initialState: string = getToken();

export function AuthTokenReducer(
  state: any = initialState,
  action: TokenActions.Actions
) {
  // console.log('denotr del reducer de token: ', action.type);
  switch (action.type) {
    case TokenActions.SET_AUTH_TOKEN:
      return action.payload;

    default:
      // console.log(state);
      return state;
  }
}

function getToken(): string {
  return Cookies.get('authToken');
}
