import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guard/auth-guard.service';
import { MainComponent } from './layouts/main/main.component';
import { DashboardComponent } from './layouts/dashboard/dashboard.component';
import { WelcomeComponent } from './layouts/dashboard/welcome/welcome.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: WelcomeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'index',
    loadChildren: './layouts/dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuardService]
  },
  {
    path: 'auth',
    loadChildren: './views/auth/auth.module#AuthModule',
    // canActivate: [AuthGuardService],
    data: {
      routeId: 1
    }
  },
  {
    path: 'products',
    loadChildren: './views/products/products.module#ProductsModule',
    canActivate: [AuthGuardService],
    data: {
      routeId: 2
    }
  },
  {
    path: 'countries',
    loadChildren: './views/countries/countries.module#CountriesModule',
    canActivate: [AuthGuardService],
    data: {
      routeId: 3
    }
  },
  {
    path: 'factories',
    loadChildren: './views/factories/factories.module#FactoriesModule',
    canActivate: [AuthGuardService],
    data: {
      routeId: 3
    }
  },
  {
    path: 'productstock',
    loadChildren: './views/stock/stock.module#StockModule',
    canActivate: [AuthGuardService],
    data: {
      routeId: 3
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
