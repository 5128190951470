import { Component, OnInit } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import * as Cookies from 'js-cookie';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {
  myUser: any = {};
  constructor() {}
  imhere: boolean;
  ngOnInit() {
    this.getData();
    this.imhere = true;
  }
  getData() {
    const a = Cookies.get('authToken');
    this.myUser = jwt_decode(a).authorizedUser[0];
  }
}
