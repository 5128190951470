import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainModule } from './layouts/main/main.module';
import { ApiService } from './services/api.service';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { AuthTokenReducer } from './store/reducers/token.reducer';
import { DashboardModule } from './layouts/dashboard/dashboard.module';
import { ToastrModule } from 'ngx-toastr';
import {
  NoopAnimationsModule,
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import { MzModalModule } from 'ngx-materialize';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSmartModalModule.forRoot(),
    BrowserAnimationsModule,
    MzModalModule,
    ToastrModule.forRoot(),
    MainModule,
    DashboardModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgGridModule.withComponents([]),
    NgxSmartModalModule.forRoot(),
    StoreModule.forRoot({
      authToken: AuthTokenReducer
    }),
    NgxCoolDialogsModule.forRoot({
      theme: 'material', // available themes: 'default' | 'material' | 'dark'
      okButtonText: 'Yes',
      cancelButtonText: 'No',
      color: '#8030c3',
      titles: {
        alert: 'Danger!',
        confirm: 'Confirmation',
        prompt: 'Website asks...'
      }
    })
  ],
  providers: [ApiService],
  bootstrap: [AppComponent]
})
export class AppModule {}
