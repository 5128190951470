import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  authToken: Observable<any>;

  constructor(
    private api: HttpClient,
    private router: Router,
    private store: Store<any>
  ) {
    this.store.select('authToken').subscribe(state => (this.authToken = state));
    // console.log(this.authToken);
  }

  canActivate(
    netxt: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authToken) {
      return true;
    }

    this.router.navigate(['auth/login']);

    return false;
  }
}
