import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { RouterModule } from '@angular/router';
// tslint:disable-next-line: max-line-length
import {
  MzValidationModule,
  MzButtonModule,
  MzInputModule,
  MzToastModule,
  MzCardModule,
  MzSidenavModule,
  MzNavbarModule,
  MzIconModule,
  MzIconMdiModule,
  MzDropdownModule
} from 'ngx-materialize';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// import {  MzIconModule, MzIconMdiModule } from 'ngx-materialize';
import { ApiService } from 'src/app/services/api.service';
import { AuthGuardService } from 'src/app/guard/auth-guard.service';
import { NavbarComponent } from './navbar/navbar.component';
import { RegisterComponent } from 'src/app/views/auth/register/register.component';
import { AuthModule } from 'src/app/views/auth/auth.module';
import { WelcomeComponent } from './welcome/welcome.component';
import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MzSidenavModule,
    MzNavbarModule,
    MzIconModule,
    MzIconMdiModule,
    MzValidationModule,
    ReactiveFormsModule,
    MzButtonModule,
    MzInputModule,
    MzIconModule,
    MzIconMdiModule,
    FormsModule,
    MzToastModule,
    MzCardModule,
    MzDropdownModule
    // DashboardRoutingModule
  ],
  declarations: [
    DashboardComponent,
    SidenavComponent,
    NavbarComponent,
    WelcomeComponent
  ],
  providers: [ApiService]
})
export class DashboardModule {}
